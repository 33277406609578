// datatable

  
  
  div.dataTables_wrapper {
	div.dataTables_filter{
	  text-align: right;
  
	  @media (max-width: 767px){
		text-align: center;
	  }
  
  
	  input{
		margin-left: 0.5em;
		margin-right: 0;
	  }
	}
  }
  
  .datatable{
	td{
	  &:focus{
		outline: none;
	  }
	}
  }
  
  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]{
	&:first-child{
	  padding-left: 0;
	}
  
	&:last-child{
	  padding-right: 0;
	}
  }
  
  
  .table.dataTable {
	thead {
  
	  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled{
		&:before{
		  left: auto;
		  right: 1em;
		}
	  }
  
	  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled{
		&:after{
		  left: auto;
		  right: 0.5em;
		}
	  }
  
	  tr{
		th, td{
		  &.sorting_asc, &.sorting_desc, &.sorting{
			padding-left: 12px;
			padding-right: 30px;
		  }
		}
	  }
	}
  }
  
  .table, table{
	&.dataTable{
	  &.dtr-inline.collapsed>tbody>tr>td{
		position: relative;
		&.dtr-control{
		  padding-left: 30px;
		  &:before{
			top: 50%;
			left: 5px;
			height: 14px;
			width: 14px;
			margin-top: -9px;
			display: block;
			position: absolute;
			color: $white;
			border: 2px solid $white;
			border-radius: 14px;
			box-sizing: content-box;
			text-align: center;
			text-indent: 0 !important;
			line-height: 14px;
			content: '+';
			background-color: $primary;
		  }
		}
	  }
	}
  }
  