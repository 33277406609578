@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

[class*=" ti-"],
[class^="ti-"] {
  font-family: themify;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  margin: 2em 0;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}

.wizard .actions>ul {
  display: inline-block !important;
  text-align: right !important;
  padding-left: 0 !important;
}

.wizard .actions>ul>li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}

.dropzone:focus {
  outline: none !important;
}

//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}

.ion-ios-checkmark-circle-outline {
  font-size: 3.5rem;
  line-height: 56px;
}

label {
  margin-bottom: 0.5rem;
}

.DraftEditor-editorContainer {
  margin-left: 14px !important;
}

.wizard .steps .nav-link {
  background-color: #daddf5;
  color: #626ed4;
}

.wizard .steps .nav-link .number {
  border-color: #626ed4;
}

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

// RightSidebar

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: $gray-300;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
}

.radio-toolbar input[type="radio"]:checked+label {
  background-color: $primary;
  border: none;
  color: $white;
}

#radio-title {
  margin-bottom: 5px;
}

//
// Button Sizes
//

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.5rem 1rem !important;
  font-size: 1.09375rem !important;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.76563rem !important;
}

.faq.accordion-button {
  &:after {
    content: "\F0377";
    display: block;
    font-family: "Material Design Icons";
    font-size: 20px;
    color: $text-muted;
    position: absolute;
    right: 20px;
    font-weight: $font-weight-medium;
    top: 50%;
    background-image: none;
    transform: translateY(-50%);
  }

  &.collapsed::after {
    content: "\F0419";
  }
}

.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-editor-main {
  border-color: $input-border-color !important;
  background-color: var(--#{$prefix}custom-white) !important;
}

.rdw-option-wrapper {
  border: none !important;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
  box-shadow: none !important;
}

.rdw-dropdown-selectedtext {
  color: var(--#{$prefix}body-color) !important;
}

// select
.select2-selection__control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.select2-selection__placeholder,
.css-14el2xx-placeholder {
  color: $input-placeholder-color !important;
}

.select2-selection__input-container,
.select2-selection__single-value {
  color: $input-color !important;
}

.select2-selection__menu-list,
.select2-selection__menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.select2-selection__menu {
  box-shadow: none !important;
  border: 1px solid $input-border-color !important;
}

.select2-selection__indicator-separator {
  background-color: $input-border-color !important;
}

.select2-selection__option--is-focused,
.select2-selection__option--is-selected {
  background-color: $primary !important;
  color: $white !important;
}

.select2-selection__group-heading {
  color: $text-muted !important;
}

.form-control__control,
.css-1s2u09g-control {
  background-color: $input-bg !important;
  border-color: $input-border-color !important;
}

.form-control__value-container,
.css-319lph-ValueContainer,
.css-g1d714-ValueContainer {
  color: $input-color !important;
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}custom-white) !important;
  border-radius: 4px !important;
}

.css-1n7v3ny-option {
  background-color: $primary !important;
  color: $white !important;
}

.select2-selection__menu-list,
.css-4ljt47-MenuList {
  background-color: $input-bg !important;
}

//* New CSS *//

.passwordInputEye {
  border-radius: 0px 6px 6px 0px !important;
  border-left: none !important;
  height: 100% !important;
}

.branchOTP input {
  margin-right: 15px !important;
  border-radius: 5px !important;
}

.react-bootstrap-table {
  overflow-x: auto !important;
}

.fieldNameLabel {
  margin-top: 10px;
  font-size: 16px;
  // margin-left: 20px;
  margin-bottom: 2px;
  color: black;
}

// ---------------------

.abc {
  padding-bottom: 125px !important
}

.btn-group> :not(.btn-check:first-child)+.DropDownBTN {
  margin-left: -1px !important;
  height: 35px !important;
  background: #e9ecef !important;
  transform: translateY(-1px) !important;
  max-width: 10px;
  border: 1px solid;
  border-color: #cfd4d9;
  margin-top: 1px;
}


input::-webkit-input-placeholder {
  text-transform: none;
}

input::-moz-placeholder {
  text-transform: none;
}

.table-fixed div .react-bootstrap-table table>tbody>tr>td:nth-last-child(1),
.table-fixed div .react-bootstrap-table table>thead>tr:nth-last-child(1)>th:nth-last-child(1) {
  background-color: #e9ecef;
  right: 0;
  position: absolute;
  // border-left: 0px solid #e9ecef;
}

.table-fixed div .react-bootstrap-table table>tbody>tr>td:nth-last-child(1) {
  border-top: 1px solid #e9ecef;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \2;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.order {
  margin-left: 15px;
}

.react-bootstrap-table-sort-order {
  margin-left: 15px;
}

.has-search .form-control {
  padding-left: 1.85rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.075rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.form-control:disabled {
  background-color: #eff2f7;
}